import {
  READ_ACTION,
  CREATE_ACTION,
  CONTRACT_ADMINISTRATION,
  CONTRACT_RESOURCE,
  FINANCIAL_SOLUTION_RESOURCE,
  PARTNER_COMPANY_RESOURCE,
  PRODUCT_RESOURCE,
  BUSINESS_INTELLIGENCE_RESOURCE,
  SUPPORT_TICKET
} from '@/constants/resources'
import { infoContracts } from '@/helpers/modalInfo'

export default [
  {
    path: '/contratos',
    name: 'contracts-list',
    component: () => import('@/modules/contract/views/ContractsList.vue'),
    meta: {
      resource: CONTRACT_RESOURCE,
      action: READ_ACTION,
      pageTitle: 'Meus contratos',
      info: infoContracts
    },
  },
  {
    path: '/contrato/:id?',
    name: 'contract-save',
    component: () => import('@/modules/contract/views/ContractSave.vue'),
    meta: {
      resource: CONTRACT_RESOURCE,
      action: CREATE_ACTION,
      pageTitle: 'Novo contrato'
    }
  },
  {
    path: '/contrato/:id/detalhes',
    component: () => import('@/modules/contract/views/ContractDetails.vue'),
    meta: {
      resource: CONTRACT_RESOURCE,
      action: READ_ACTION
    },
    children: [
      {
        path: 'informacoes_gerais',
        name: 'contract-details-summary',
        component: () => import('@/modules/contract/views/ContractDetailsSummary.vue'),
        meta: {
          contentRenderer: 'sidebar-left',
          contentClass: 'todo-application',
          navActiveLink: 'contracts-list'
        },
      },
      {
        path: 'pagamentos',
        name: 'contract-details-payments',
        component: () => import('@/modules/contract/views/ContractDetailsPayments.vue'),
        meta: {
          contentRenderer: 'sidebar-left',
          contentClass: 'todo-application',
          navActiveLink: 'contracts-list'
        },
      },
      {
        path: 'comissoes',
        name: 'contract-details-commissions',
        component: () => import('@/modules/contract/views/ContractDetailsCommissions.vue'),
        meta: {
          contentRenderer: 'sidebar-left',
          contentClass: 'todo-application',
          navActiveLink: 'contracts-list'
        },
      },
      {
        path: 'anexos',
        name: 'contract-details-files',
        component: () => import('@/modules/contract/views/ContractDetailsFiles.vue'),
        meta: {
          contentRenderer: 'sidebar-left',
          contentClass: 'todo-application',
          navActiveLink: 'contracts-list'
        },
      },
      {
        path: 'historico',
        name: 'contract-details-history',
        component: () => import('@/modules/contract/views/ContractDetailsHistory.vue'),
        meta: {
          contentRenderer: 'sidebar-left',
          contentClass: 'todo-application',
          navActiveLink: 'contracts-list'
        },
      },
      {
        path: 'comentarios',
        name: 'contract-details-comments',
        component: () => import('@/modules/contract/views/ContractDetailsComments.vue'),
        meta: {
          contentRenderer: 'sidebar-left',
          contentClass: 'todo-application',
          navActiveLink: 'contracts-list'
        },
      }
    ]
  },
  {
    path: '/solucoes-financeiras',
    name: 'financial-solutions-list',
    component: () => import('@/modules/financial_solution/views/FinancialSolutionsList.vue'),
    meta: {
      resource: FINANCIAL_SOLUTION_RESOURCE,
      action: READ_ACTION,
      pageTitle: 'Soluções financeiras'
    }
  },
  {
    path: '/solucao-financeira/:id?',
    name: 'financial-solution-save',
    component: () => import('@/modules/financial_solution/views/FinancialSolutionSave.vue'),
    meta: {
      resource: FINANCIAL_SOLUTION_RESOURCE,
      action: CREATE_ACTION,
      pageTitle: 'Nova solução financeira'
    },
    props:{
      'isCopy': false
    }
  },
  {
    path: '/duplicar-solucao-financeira/:id?',
    name: 'financial-solution-copy',
    component: () => import('@/modules/financial_solution/views/FinancialSolutionSave.vue'),
    meta: {
      resource: FINANCIAL_SOLUTION_RESOURCE,
      action: CREATE_ACTION,
      pageTitle: 'Duplicar solução financeira'
    },
    props:{
      'isCopy': true
    }
  },
  {
    path: '/empresas',
    name: 'companies-list',
    component: () => import('@/modules/partner_company/views/PartnerCompaniesList.vue'),
    meta: {
      resource: PARTNER_COMPANY_RESOURCE,
      action: READ_ACTION,
      pageTitle: 'Empresas'
    }
  },
  {
    path: '/empresa/:id?',
    name: 'company-save',
    component: () => import('@/modules/partner_company/views/PartnerCompanySave.vue'),
    meta:{
      resource: PARTNER_COMPANY_RESOURCE,
      action:  CREATE_ACTION,
      pageTitle: 'Nova Empresa'
    }
  },
  {
    path: '/empresa/:id',
    name: 'company-show',
    component: () => import('@/modules/partner_company/views/PartnerCompanySave.vue'),
    meta: {
      resource: PARTNER_COMPANY_RESOURCE,
      action:  READ_ACTION,
      pageTitle: 'Visualizar Empresa'
    }
  },
  {
    path: '/produtos',
    name: 'products-list',
    component: () => import('@/modules/product/views/ProductsList.vue'),
    meta: {
      resource: PRODUCT_RESOURCE,
      action: READ_ACTION,
      pageTitle: 'Produtos'
    }
  },
  {
    path: '/produto/:id?',
    name: 'product-save',
    component: () => import('@/modules/product/views/ProductSave.vue'),
    meta: {
      resource: PRODUCT_RESOURCE,
      action: CREATE_ACTION,
      pageTitle: 'Novo produto'
    }
  },


  {
    path: '/requisicoes-exclusao',
    name: 'deletion-request-list',
    component: () => import('@/modules/contract/views/ContractDeletionRequestList.vue'),
    meta: {
      resource: CONTRACT_RESOURCE,
      action: READ_ACTION,
      pageTitle: 'Solicitações de exclusão'
    }
  },
  {
    path: '/administracao-contratos',
    name: 'contract-administration',
    component: () => import('@/modules/contract/views/ContractAdmistration.vue'),
    meta: {
      resource: BUSINESS_INTELLIGENCE_RESOURCE,
      action: CONTRACT_ADMINISTRATION,
      pageTitle: 'Administração de contratos'
    }
  },
  {
    path: '/chamados',
    name: 'support-tickets',
    component: () => import('@/modules/support_ticket/views/SupportTicketList.vue'),
    meta: {
      resource: SUPPORT_TICKET,
      action: READ_ACTION,
      pageTitle: 'Gestão de chamados'
    }
  },

  {
    path: '/ticket/:id/detalhes',
    component: () => import('@/modules/support_ticket/views/TicketDetails.vue'),
    meta: {
      resource: CONTRACT_RESOURCE,
      action: READ_ACTION
    },
    children: [
      {
        path: 'informacoes_gerais',
        name: 'ticket-details-summary',
        component: () => import('@/modules/support_ticket/views/TicketDetailsSummary.vue'),
        meta: {
          contentRenderer: 'sidebar-left',
          contentClass: 'todo-application'
        },
      },
      {
        path: 'historico',
        name: 'ticket-details-history',
        component: () => import('@/modules/support_ticket/views/TicketDetailsHistory.vue'),
        meta: {
          contentRenderer: 'sidebar-left',
          contentClass: 'todo-application'
        },
      },
    ]
  },


]
