import {
  READ_ACTION,
  CREATE_ACTION,
  LEAD_RESOURCE,
  PENDING_INTERVIEWS_ACTION,
  BUSINESS_INTELLIGENCE_RESOURCE,
  TRANSFER_ACTION,
  CUSTOMER_RESOURCE,
} from "@/constants/resources";

import {
  PROFILE_TYPE_CONSULTANT,
  PROFILE_TYPE_CUSTOMER,
  PROFILE_TYPE_LEAD,
} from "@/constants/profile";

import {
  leadCustomer,
  customer,
  appointment,
  leadPn,
  transferCustomers,
} from "@/helpers/modalInfo";

export default [
  {
    path: "/franquias-fisicas",
    name: "physical-franchise-list",
    component: () =>
      import("@/modules/franchises/views/PhysicalFranchiseList.vue"),
    meta: {
      resource: LEAD_RESOURCE,
      action: READ_ACTION,
      pageTitle: "Franquias físicas",
    },
  },
  {
    path: "/franquias-digitais",
    name: "franchisees-list",
    component: () => import("@/modules/franchises/views/FranchiseesList.vue"),
    meta: {
      resource: LEAD_RESOURCE,
      action: READ_ACTION,
      pageTitle: "Franquias digitais",
    },
  },
];
