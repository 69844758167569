import {
  READ_ACTION,
  CREATE_ACTION,
  LEAD_RESOURCE,
  PENDING_INTERVIEWS_ACTION,
  BUSINESS_INTELLIGENCE_RESOURCE,
  TRANSFER_ACTION,
  CUSTOMER_RESOURCE,
} from "@/constants/resources";
import { infoDashboard } from "@/helpers/modalInfo";

export default [
  {
    path: "/dashboard",
    name: "dashboard",
    component: () => import("@/modules/dashboard/views/Dashboard.vue"),
    meta: {
      resource: CUSTOMER_RESOURCE,
      action: READ_ACTION,
      pageTitle: "Dashboard",
      info: infoDashboard
    },
  }
];
