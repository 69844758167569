import axios from 'axios'
import * as types from './types'

export default {
  [types.GET_CONSULTANTS]: ({ commit }, payload) => {
    return axios.get('/api/consultants/summary', { params: payload })
      .then(resp => {
        commit(types.MUTATE_CONSULTANTS, resp.data)
        return Promise.resolve(resp)
      })
      .catch(err => {
        return Promise.reject(err)
      })
  }, 
  [types.GET_FINANCIAL_SOLUTION]: ({}, payload) => {
    return axios.get(`/api/financial_solutions/${payload}`)
      .then(resp => {
        return Promise.resolve(resp)
      })
      .catch(err => {
        return Promise.reject(err)
      })
  },
  [types.GET_FINANCIAL_SOLUTIONS]: ({ commit }, payload) => {
    return axios.get('/api/financial_solutions/summary', { params: payload })
      .then(resp => {
        commit(types.MUTATE_FINANCIAL_SOLUTIONS, resp.data)
        return Promise.resolve(resp)
      })
      .catch(err => {
        return Promise.reject(err)
      })
  },
  [types.GET_FINANCIAL_SOLUTION_FIELDS]: ({ commit }, payload) => {
    return axios.get('/api/financial_solutions/fields/summary', { params: payload })
      .then(resp => {
        commit(types.MUTATE_FINANCIAL_SOLUTION_FIELDS, resp.data)
        return Promise.resolve(resp)
      })
      .catch(err => {
        return Promise.reject(err)
      })
  },
  [types.GET_FINANCIAL_SOLUTION_CALCULATION_RULES]: ({ commit }, payload) => {
    return axios.get('/api/financial_solutions/calculation_rules/summary', { params: payload })
      .then(resp => {
        commit(types.MUTATE_FINANCIAL_SOLUTION_CALCULATION_RULES, resp.data)
        return Promise.resolve(resp)
      })
      .catch(err => {
        return Promise.reject(err)
      })
  },
  [types.SEARCH_CONTRACTS]: ({ commit }, payload) => {
    const resource = `/api/contracts/search?page=${payload.currentPage}`
    delete payload.currentPage;
    return axios.post(resource, payload)
      .then(resp => {
        commit(types.MUTATE_CONTRACTS_SEARCH_RESULT, resp.data)
        return Promise.resolve(resp)
      })
      .catch(err => {
        return Promise.reject(err)
      })
  },
  [types.EXPORT_CONTRACTS]: ({}, payload) => {
    return axios.post(
      '/api/contracts/export',
      payload,
      { responseType: 'blob' },
    )
      .then(resp => {
        let fileURL = window.URL.createObjectURL(new Blob([resp.data]));
        let fileLink = document.createElement('a');
        fileLink.href = fileURL;
        fileLink.setAttribute('download', 'futuro_contratos.xlsx');
        document.body.appendChild(fileLink);
        fileLink.click();
        document.body.removeChild(fileLink);
        return Promise.resolve(resp)
      })
      .catch(err => {
        return Promise.reject(err)
      })
  },
  [types.GET_CONTRACT]: ({}, payload) => {
    return axios.get(`/api/products/${payload}`)
      .then(resp => {
        return Promise.resolve(resp)
      })
      .catch(err => {
        return Promise.reject(err)
      })
  },
  [types.SAVE_CONTRACT]: ({}, payload) => {
    let save = axios.post
    let route = '/api/contracts/manual'
    if (payload.id) {
      save = axios.put
      route = `/api/contracts/manual/${payload.id}`
    }
    return save(route, payload)
      .then(resp => {
        return Promise.resolve(resp)
      })
      .catch(err => {
        return Promise.reject(err)
      })
  },
  [types.UPDATE_CONTRACT]: ({}, payload) => {
    return axios.put(`/api/contracts/${payload.id}`, payload)
      .then(resp => {
        return Promise.resolve(resp)
      })
      .catch(err => {
        return Promise.reject(err)
      })
  },
  [types.SAVE_ATTACHMENT]: ({}, payload) => {
      return axios.post('/api/contracts/upload_attachment', payload)
        .then(resp => {
          return Promise.resolve(resp)
        })
        .catch(err => {
          return Promise.reject(err)
        })
  },
  [types.GET_STATUS_LIST]: ({ commit }, payload) => {
    return axios.get('/api/contracts/statuses/summary', { params: payload })
      .then(resp => {
        commit(types.MUTATE_STATUS_LIST, resp.data)
        return Promise.resolve(resp)
      })
      .catch(err => {
        return Promise.reject(err)
      })
  },
  [types.SAVE_RECEIPT]: ({}, payload) => {
    return axios.post('/api/payments/upload_manual', payload)
      .then(resp => {
        return Promise.resolve(resp)
      })
      .catch(err => {
        return Promise.reject(err)
      })
  },
  [types.SAVE_REQUEST_DELETION_SIDEBAR]: ({ }, payload) => {
    return axios.post('/api/contracts/deletion_request', payload)
      .then(resp => {
        return Promise.resolve(resp)
      })
      .catch(err => {
        return Promise.reject(err)
      })
  },

  [types.GET_SNAPSHOTS]: ({}, payload) => {
    return axios.get(`/api/commissions/snapshots/${payload}`)
      .then(resp => {
        return Promise.resolve(resp)
      })
      .catch(err => {
        return Promise.reject(err)
      })
  },
  [types.UPDATE_SNAPSHOTS]: ({}, payload) => {
    return axios.put(`/api/commissions/snapshots/${payload.contractId}`, payload)
      .then(resp => {
        return Promise.resolve(resp)
      })
      .catch(err => {
        return Promise.reject(err)
      })
  },
  [types.UPDATE_PAYMENTS]: ({}, payload) => {
    return axios.get(`/api/payments/update_payments/${payload}`)
      .then(resp => {
        return Promise.resolve(resp)
      })
      .catch(err => {
        return Promise.reject(err)
      })
  },
  [types.GET_COMMENTS]: ({commit}, payload) => {
    return axios.get(`/api/contracts/comments/summary/${payload.id}`)
      .then(resp => {
        commit(types.MUTATE_COMMENTS, resp.data)
        return Promise.resolve(resp)
      })
      .catch(err => {
        return Promise.reject(err)
      })
  },
  [types.SAVE_COMMENT]: ({}, payload) => {
    return axios.post(`/api/contracts/comments`, payload)
      .then(resp => {
        return Promise.resolve(resp)
      })
      .catch(err => {
        return Promise.reject(err)
      })
  },
  [types.DELETE_COMMENT]: ({}, payload) => {
    return axios.delete(`/api/contracts/comments/${payload}`)
      .then(resp => {
        return Promise.resolve(resp)
      })
      .catch(err => {
        return Promise.reject(err)
      })
  },
  [types.OPEN_EDIT_CONTRACT_SIDEBAR]: ({ commit }, payload) => {
    commit(types.MUTATE_EDIT_CONTRACT_SIDEBAR, { ...payload, visible: true })
  },
  [types.OPEN_ADD_RECEIPT_SIDEBAR]: ({ commit }, payload) => {
    commit(types.MUTATE_ADD_RECEIPT_SIDEBAR, { ...payload, visible: true })
  },
  [types.OPEN_REQUEST_DELETION_SIDEBAR]: ({ commit }, payload) => {
    commit(types.MUTATE_REQUEST_DELETION_SIDEBAR, { ...payload, visible: true })
  },
  [types.OPEN_REQUEST_DELETION_APROVAL_SIDEBAR]: ({ commit }, payload) => {
    commit(types.MUTATE_REQUEST_DELETION_APROVAL_SIDEBAR, { ...payload, visible: true })
  },
  [types.OPEN_EDIT_SNAPSHOT_SIDEBAR]: ({ commit }, payload) => {
    commit(types.MUTATE_EDIT_SNAPSHOT_SIDEBAR, { ...payload, visible: true })
  },
  [types.OPEN_EDIT_CONTRACT_OWNER_SIDEBAR]: ({ commit }, payload) => {
    commit(types.MUTATE_EDIT_CONTRACT_OWNER_SIDEBAR, { ...payload, visible: true })
  },
  [types.GET_DETAILS_GENERAL_INFORMATION]: ({ commit }, payload) => {
    commit(types.CLEAR_DETAILS_GENERAL_INFORMATION)
    return axios.get(`/api/contracts/${payload}/details/general_information`)
      .then(resp => {
        commit(types.MUTATE_DETAILS_GENERAL_INFORMATION, resp.data)
        return Promise.resolve(resp)
      })
      .catch(err => {
        return Promise.reject(err)
      })
  },
  [types.GET_PAYMENTS]: ({commit}, payload) => {
    return axios.get(`/api/contracts/${payload.id}/payments`)
      .then(resp => {
        commit(types.MUTATE_PAYMENTS, resp.data)
        return Promise.resolve(resp)
      })
      .catch(err => {
        return Promise.reject(err)
      })
  },
  [types.SEARCH_DELETION_REQUEST]: ({ commit }, payload) => {
    const resource = `/api/contracts/deletion_request/search?page=${payload.currentPage}`
    delete payload.currentPage;
    return axios.post(resource, payload)
      .then(resp => {
        commit(types.MUTATE_DELETION_REQUEST_SEARCH_RESULT, resp.data)
        return Promise.resolve(resp)
      })
      .catch(err => {
        return Promise.reject(err)
      })
  },
  [types.GET_COMMISSIONS]: ({commit}, payload) => {
    return axios.get(`/api/contracts/${payload.id}/commissions`)
      .then(resp => {
        commit(types.MUTATE_COMMISSIONS, resp.data)
        return Promise.resolve(resp)
      })
      .catch(err => {
        return Promise.reject(err)
      })
  },
  [types.DELETE_DELETION_REQUEST]: ({}, payload) => {
    const resource = `/api/contracts/deletion_request/to_accept`
    return axios.post(resource, payload)
      .then(resp => {
        return Promise.resolve(resp)
      })
      .catch(err => {
        return Promise.reject(err)
      })
  },
  [types.GET_FILES]: ({commit}, payload) => {
    return axios.get(`/api/contracts/${payload.id}/attachments`)
      .then(resp => {
        commit(types.MUTATE_FILES, resp.data)
        return Promise.resolve(resp)
      })
      .catch(err => {
        return Promise.reject(err)
      })
  },
  [types.DOWNLOAD_FILE]: ({}, payload) => {
    return axios.get(`/api/contracts/download_attachment/${payload}`)
      .then(resp => {
        return Promise.resolve(resp)
      })
      .catch(err => {
        return Promise.reject(err)
      })
  },
  [types.GET_HISTORY]: ({commit}, payload) => {
    return axios.get(`/api/contracts/${payload.id}/history`)
      .then(resp => {
        commit(types.MUTATE_HISTORY, resp.data)
        return Promise.resolve(resp)
      })
      .catch(err => {
        return Promise.reject(err)
      })
  },
  [types.SAVE_REQUEST_DELETION_APROVAL_SIDEBAR]: ({ }, payload) => {
    return axios.post('/api/contracts/deletion_request/refuse_request', payload)
      .then(resp => {
        return Promise.resolve(resp)
      })
      .catch(err => {
        return Promise.reject(err)
      })
  },
  [types.REFUSE_SELECTED_DELETION_REQUEST]: ({ }, payload) => {
    return axios.post('/api/contracts/deletion_request/refuse_selected', payload)
      .then(resp => {
        return Promise.resolve(resp)
      })
      .catch(err => {
        return Promise.reject(err)
      })
  },
  [types.GET_CUSTOMER_APPOINTMENTS]: ({commit}, payload) => {
    return axios.post(`/api/customers_info/appointments`, payload)
      .then(resp => {
        commit(types.MUTATE_CUSTOMER_APPOINTMENTS, resp.data)
        return Promise.resolve(resp)
      })
      .catch(err => {
        return Promise.reject(err)
      })
  },
  [types.RESET_DAYS_ON_STATUS]: ({}, payload) => {
    return axios.put(`/api/contracts/statuses/reset_days/${payload.id}`)
      .then(resp => {
        return Promise.resolve(resp)
      })
      .catch(err => {
        return Promise.reject(err)
      })
  },
  [types.DELETE_SELECTED_DELETION_REQUEST]: ({}, payload) => {
    const resource = `/api/contracts/deletion_request/delete_selected`
    return axios.post(resource, payload)
      .then(resp => {
        return Promise.resolve(resp)
      })
      .catch(err => {
        return Promise.reject(err)
      })
  },
  [types.GET_CONSULTANTS_ROLES]: ({}, payload) => {
    return axios.get(`/api/consultants/roles`)
      .then(resp => {
        return Promise.resolve(resp)
      })
      .catch(err => {
        return Promise.reject(err)
      })
  },
  [types.CALCULATE_SNAPSHOT_DISTRIBUTION]: ({}, payload) => {
    const resource = `/api/commissions/snapshots/calculate/${payload.contractId}`
    delete payload.contractId;
    return axios.post(resource, payload)
      .then(resp => {
        return Promise.resolve(resp)
      })
      .catch(err => {
        return Promise.reject(err)
      })
  },
  [types.DELETE_PAYMENT]: ({}, payload) => {
    return axios.delete(`/api/payments/${payload.id}`)
      .then(resp => {
        return Promise.resolve(resp)
      })
      .catch(err => {
        return Promise.reject(err)
      })
  },
  [types.REVERSAL_CONTRACT_PAYMENTS]: ({}, payload) => {
    return axios.delete(`/api/payments/reversal_contract/${payload.id}`)
      .then(resp => {
        return Promise.resolve(resp)
      })
      .catch(err => {
        return Promise.reject(err)
      })
  },
  [types.REVERSAL_PAYMENT]: ({}, payload) => {
    return axios.delete(`/api/payments/reversal/${payload.id}`)
      .then(resp => {
        return Promise.resolve(resp)
      })
      .catch(err => {
        return Promise.reject(err)
      })
  },
  [types.UPDATE_CONTRACT_OWNER]: ({}, payload) => {
    return axios.put(`/api/contracts/owner`, payload)
      .then(resp => {
        return Promise.resolve(resp)
      })
      .catch(err => {
        return Promise.reject(err)
      })
  },
  [types.OPEN_QUERY_ASAAS_ID_SIDEBAR]: ({ commit }, payload) => {
    commit(types.MUTATE_QUERY_ASAAS_ID_SIDEBAR, { ...payload, visible: true })
  },
  [types.GET_ASAAS_ID]: ({}, payload) => {
    return axios.post(`/api/contracts/asaas`, payload)
      .then(resp => {
        return Promise.resolve(resp)
      })
      .catch(err => {
        return Promise.reject(err)
      })
  },
  [types.SAVE_CONTRACT_CUSTOM_FIELD]: ({}, payload) => {
    const contract_id = payload.id
    delete payload.id
    return axios.put(`/api/contracts/custom_field/${contract_id}`, payload)
      .then(resp => {
        return Promise.resolve(resp)
      })
      .catch(err => {
        return Promise.reject(err)
      })
  },
}
