import {
  READ_ACTION,
  BUSINESS_SCHOOL_PROGRESS,
  REPORT_RESOURCE,
  BUSINESS_INTELLIGENCE_RESOURCE
} from '@/constants/resources'
  


export default [
  {
    path: '/relatorio/assinaturas-inadimplentes',
    name: 'overdue-signature-report',
    component: () => import('@/modules/report/views/OverdueSignatureReport.vue'),
    meta: {
      resource: REPORT_RESOURCE,
      action: READ_ACTION,
      pageTitle: 'Relatório de assinaturas inadimplentes'
    }
  },
  {
    path: '/relatorio/contratos-pns-inativos',
    name: 'inactive-pn-contracts-report',
    component: () => import('@/modules/report/views/InactiveConsultantContractsReport.vue'),
    meta: {
      resource: REPORT_RESOURCE,
      action: READ_ACTION,
      pageTitle: 'Relatório de contratos de PNs inativos'
    }
  },
  {
    path: '/relatorio/clientes-pns-inativos',
    name: 'inactive-pn-customers-report',
    component: () => import('@/modules/report/views/InactiveConsultantCustomersReport.vue'),
    meta: {
      resource: REPORT_RESOURCE,
      action: READ_ACTION,
      pageTitle: 'Relatório de clientes de PNs inativos'
    }
  },
  {
    path: '/relatorio/progresso-escola-negocios',
    name: 'business-school-progress',
    component: () => import('@/modules/report/views/BusinessSchoolProgressReport.vue'),
    meta: {
      resource: BUSINESS_INTELLIGENCE_RESOURCE,
      action: BUSINESS_SCHOOL_PROGRESS,
      pageTitle: 'Progresso escola de negócios '
    }
  },
]
  