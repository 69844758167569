import * as types from "./types";
import _ from "lodash";

const mergeAndSumCommissionAttributes = (target, source) => {
  const newObject = { ...target };
  Object.keys(source).forEach((key) => {
    if (
      source.hasOwnProperty(key) &&
      typeof newObject[key] === "number" &&
      typeof source[key] === "number" &&
      key !== "has_details"
    ) {
      newObject[key] += source[key];
    } else if (source[key] !== null && key !== "has_details") {
      newObject[key] = source[key];
    } else if (key === "has_details") {
      newObject[key] = source[key] || newObject[key];
    }
  });
  return newObject;
};

const getResultComissionObjectTemplate = () => {
  return {
    ep_expected_amount: 0,
    ep_expected_count: 0,
    ep_paid_amount: 0,
    ep_paid_count: 0,
    financial_solutions_expected_amount: 0,
    financial_solutions_expected_count: 0,
    financial_solutions_paid_amount: 0,
    financial_solutions_paid_count: 0,
    subscription_expected_amount: 0,
    subscription_expected_count: 0,
    subscription_paid_amount: 0,
    subscription_paid_count: 0,
    has_details: 0,
  };
};

export default {
  [types.MUTATE_CURRENT_COMMISSIONS_SEARCH_RESULT]: (state, payload) => {
    state.currentSearchResult = payload;
  },
  [types.MUTATE_HISTORICAL_COMMISSIONS_SEARCH_RESULT]: (state, payload) => {
    state.historicalSearchResult = payload;
  },
  [types.MUTATE_PAST_FUTURE_COMMISSIONS_SEARCH_RESULT]: (state, payload) => {
    try {
      const { expected, billed, imported, adjustments } = payload;
      let months = _.merge(_.map(expected, "date"), _.map(billed, "date"), _.map(imported, "date"));
      let items = _.map(months, (month) => {
        return {
          date: month,
          ...getResultComissionObjectTemplate()
        }
      })  
      // Creating the list
      items = _.map(items, (item) => {
        const match = _.find(expected, { date: item.date });
        return match ? mergeAndSumCommissionAttributes(item, match) : item;
      });
      items = _.map(items, (item) => {
        const match = _.find(billed, { date: item.date });
        return match ? mergeAndSumCommissionAttributes(item, match) : item;
      });
      items = _.map(items, (item) => {
        const match = _.find(imported, { date: item.date });
        return match ? mergeAndSumCommissionAttributes(item, match) : item;
      });

      // Calculating totals by financial solution type
      const totalsByType = {
        ep_expected_amount: _.sumBy(items, "ep_expected_amount"),
        ep_expected_count: _.sumBy(items, "ep_expected_count"),
        ep_paid_amount: _.sumBy(items, "ep_paid_amount"),
        ep_paid_count: _.sumBy(items, "ep_paid_count"),
        financial_solutions_expected_amount: _.sumBy(
          items,
          "financial_solutions_expected_amount"
        ),
        financial_solutions_expected_count: _.sumBy(
          items,
          "financial_solutions_expected_count"
        ),
        financial_solutions_paid_amount: _.sumBy(
          items,
          "financial_solutions_paid_amount"
        ),
        financial_solutions_paid_count: _.sumBy(
          items,
          "financial_solutions_paid_count"
        ),
        subscription_expected_amount: _.sumBy(
          items,
          "subscription_expected_amount"
        ),
        subscription_expected_count: _.sumBy(
          items,
          "subscription_expected_count"
        ),
        subscription_paid_amount: _.sumBy(items, "subscription_paid_amount"),
        subscription_paid_count: _.sumBy(items, "subscription_paid_count"),
      };

      state.pastFutureSearchResult = {
        items,
        totalsByType,
        adjustments
      };
    } catch (error) {
      console.error(
        "Error in MUTATE_PAST_FUTURE_COMMISSIONS_SEARCH_RESULT",
        error
      );
    }
  },
  [types.MUTATE_COMMISSION_PRODUCTS]: (state, payload) => {
    state.commissionProducts = payload;
  },
  [types.MUTATE_COMMISSION_MANAGEMENT_SEARCH_RESULT]: (state, payload) => {
    state.searchResult = payload;
  },
  [types.MUTATE_PAYMENT_CONCILIATORS]: (state, payload) => {
    state.paymentConciliators = payload;
  },
  [types.MUTATE_FINANCIAL_SOLUTIONS]: (state, payload) => {
    state.financialSolutions = payload;
  },
  [types.MUTATE_PAYMENT_PRODUCTS]: (state, payload) => {
    state.paymentProducts = payload;
  },
  [types.MUTATE_COMMISSION_MANAGEMENT_TOTALIZER]: (state, payload) => {
    state.commissionManagementTotalizer = payload;
  },
  [types.MUTATE_CHART_COMMISSION_MANAGEMENT]: (state, payload) => {
    state.chartResult = payload;
  },
};
