// Actions

export const MANAGE_ACTION = 'manage'

export const CREATE_ACTION = 'create'

export const UPDATE_ACTION = 'update'

export const READ_ACTION = 'read'

export const DELETE_ACTION = 'delete'

export const PENDING_INTERVIEWS_ACTION = 'pending_interviews'

export const TRANSFER_ACTION = 'transfer'

export const CONTRACT_ADMINISTRATION = 'contract_administration'

export const BUSINESS_SCHOOL_PROGRESS= 'business_school_progress'

export const OPPORTUNITY_MAP_ACTION = 'opportunity_map'

export const DIGITAL_FRANCHISE_READ = 'digital_franchise_read'

export const SUBSCRIPTIONS_STATICS = 'subscriptions_statics'

// API resources

export const CONTRACT_RESOURCE = 'contract'

export const FINANCIAL_SOLUTION_RESOURCE = 'financial_solution'

export const PARTNER_COMPANY_RESOURCE = 'partner_company'

export const PRODUCT_RESOURCE = 'product'

export const INTERVIEW_RESOURCE = 'interview'

export const INTERVIEW_PROPOSAL_RESOURCE = 'interview_proposal'

export const CONSULTANT_RESOURCE = 'consultant'

export const REPORT_RESOURCE = 'report'

export const LEAD_RESOURCE = 'lead'

export const BUSINESS_INTELLIGENCE_RESOURCE = 'bi'

export const ANNOUNCEMENT_RESOURCE = 'announcement'

export const CUSTOMER_RESOURCE = 'customer'

export const WITHDRAWAL_RESOURCE = 'withdrawal'

export const FINANCIAL_ADJUSTMENTS = 'financial_adjustment'

export const FINANCIAL_MANAGMENT = 'financial_management'

export const PAYMENT_IMPORT = 'payment_import'

export const NPS_SURVEY = 'nps_survey'

export const CAMPAIGN = 'campaign'

export const DASHBOARD_BANNER = 'dashboard_banner'

export const SUPPORT_TICKET = 'support_tickets'

export const COMMISSION_MANAGEMENT = 'commission_management'

export const EXPENSE = 'expense'