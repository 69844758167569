// Getters
export const BILLS_SEARCH_RESULT = 'financial_management/BILLS_SEARCH_RESULT'
export const PAYMENT_SIDEBAR = 'financial_management/PAYMENT_SIDEBAR'
export const BILL_DETAILS_SIDEBAR = 'financial_management/BILL_DETAILS_SIDEBAR'
export const PAID_BILLS_SEARCH_RESULT = 'financial_management/PAID_BILLS_SEARCH_RESULT'
export const EXPECTED_BILLS_SEARCH_RESULT = 'financial_management/EXPECTED_BILLS_SEARCH_RESULT'
export const ACTIVE_BILLING_MONTH = 'financial_management/ACTIVE_BILLING_MONTH'
export const CONTRACT_PAYMENTS_SEARCH_RESULT = 'financial_management/CONTRACT_PAYMENTS_SEARCH_RESULT'
export const PAYMENT_CONCILIATORS = 'financial_management/PAYMENT_CONCILIATORS'
export const PAYMENT_PRODUCTS = 'financial_management/PAYMENT_PRODUCTS'
export const PAYMENTS_IMPORTS_LIST = 'financial_management/PAYMENTS_IMPORTS_LIST'
export const IMPORT_PAYMENTS_SIDEBAR = 'financial_management/IMPORT_PAYMENTS_SIDEBAR'
export const PAYMENTS_IMPORTS_DETAILS_LIST = 'financial_management/PAYMENTS_IMPORTS_DETAILS_LIST'
export const PAYMENTS_IMPORTS_DETAILS_TOTALIZER = 'financial_management/PAYMENTS_IMPORTS_DETAILS_TOTALIZER'

// Mutations
export const MUTATE_BILLS_SEARCH_RESULT = 'financial_management/MUTATE_BILLS_SEARCH_RESULT'
export const MUTATE_PAYMENT_SIDEBAR = 'financial_management/MUTATE_PAYMENT_SIDEBAR'
export const MUTATE_BILL_DETAILS_SIDEBAR = 'financial_management/MUTATE_BILL_DETAILS_SIDEBAR'
export const MUTATE_PAID_BILLS_SEARCH_RESULT = 'financial_management/MUTATE_PAID_BILLS_SEARCH_RESULT'
export const MUTATE_EXPECTED_BILLS_SEARCH_RESULT = 'financial_management/MUTATE_EXPECTED_BILLS_SEARCH_RESULT'
export const MUTATE_ACTIVE_BILLING_MONTH = 'financial_management/MUTATE_ACTIVE_BILLING_MONTH'
export const MUTATE_CONTRACT_PAYMENTS_SEARCH_RESULT = 'financial_management/MUTATE_CONTRACT_PAYMENTS_SEARCH_RESULT'
export const MUTATE_PAYMENT_CONCILIATORS = 'financial_management/MUTATE_PAYMENT_CONCILIATORS'
export const MUTATE_PAYMENT_PRODUCTS = 'financial_management/MUTATE_PAYMENT_PRODUCTS'
export const MUTATE_PAYMENTS_IMPORTS_LIST = 'financial_management/MUTATE_PAYMENTS_IMPORTS_LIST'
export const MUTATE_IMPORT_PAYMENTS_SIDEBAR = 'financial_management/MUTATE_IMPORT_PAYMENTS_SIDEBAR'
export const MUTATE_PAYMENTS_IMPORTS_DETAILS_LIST = 'financial_management/MUTATE_PAYMENTS_IMPORTS_DETAILS_LIST'
export const MUTATE_PAYMENTS_IMPORTS_DETAILS_TOTALIZER = 'financial_management/MUTATE_PAYMENTS_IMPORTS_DETAILS_TOTALIZER'

// Actions
export const SEARCH_BILLS = 'financial_management/SEARCH_BILLS'
export const DOWNLOAD_DOCUMENT = 'financial_management/DOWNLOAD_DOCUMENT'
export const OPEN_PAYMENT_SIDEBAR = 'financial_management/OPEN_PAYMENT_SIDEBAR'
export const GET_BILL_DETAILS = 'financial_management/GET_BILL_DETAILS'
export const STORE_PAYMENT_REVIEW = 'financial_management/STORE_PAYMENT_REVIEW'
export const OPEN_BILL_DETAILS_SIDEBAR = 'financial_management/OPEN_BILL_DETAILS_SIDEBAR'
export const GET_INVOICE_HISTORY = 'financial_management/GET_INVOICE_HISTORY'
export const SEARCH_PAID_BILLS = 'financial_management/SEARCH_PAID_BILLS'
export const SEARCH_EXPECTED_BILLS = 'financial_management/SEARCH_EXPECTED_BILLS'
export const GET_ACTIVE_BILLING_MONTH = 'financial_management/GET_ACTIVE_BILLING_MONTH'
export const IMPORT_FINANCIAL_SOLUTION_PAYMENTS = 'financial_management/IMPORT_FINANCIAL_SOLUTION_PAYMENTS'
export const GET_IMPORT_STATUS = 'financial_management/GET_IMPORT_STATUS'
export const DOWNLOAD_IMPORT_FILE = 'financial_management/DOWNLOAD_IMPORT_FILE'
export const SEARCH_CONTRACT_PAYMENTS = 'financial_management/SEARCH_CONTRACT_PAYMENTS'
export const GET_PAYMENT_CONCILIATORS = 'financial_management/GET_PAYMENT_CONCILIATORS'
export const GET_PAYMENT_PRODUCTS = 'financial_management/GET_PAYMENT_PRODUCTS'
export const SEARCH_PAYMENTS_IMPORTS = 'financial_management/SEARCH_PAYMENTS_IMPORTS'
export const OPEN_IMPORT_PAYMENTS_SIDEBAR = 'financial_management/OPEN_IMPORT_PAYMENTS_SIDEBAR'
export const GET_PAYMENTS_IMPORTS_DETAILS_LIST = 'financial_management/GET_PAYMENTS_IMPORTS_DETAILS_LIST'
export const GET_PAYMENTS_IMPORTS_DETAILS_TOTALIZER = 'financial_management/GET_PAYMENTS_IMPORTS_DETAILS_TOTALIZER'
export const ROLLBACK_PAYMENTS_IMPORT = 'financial_management/ROLLBACK_PAYMENTS_IMPORT'
export const DOWNLOAD_PAYMENTS_PROVISIONED_REPORT = 'financial_management/DOWNLOAD_PAYMENTS_PROVISIONED_REPORT'