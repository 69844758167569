import {
  FINANCIAL_MANAGMENT,
  PAYMENT_IMPORT,
  READ_ACTION
} from '@/constants/resources'

import * as types from '@/constants/financial_management_types'


export default [
  {
    path: '/contas/pagas',
    name: 'financial-management-payables-paid',
    component: () => import('@/modules/financial_management/views/FinancialManagement.vue'),
    meta: {
      resource: FINANCIAL_MANAGMENT,
      action: READ_ACTION,
    },
    props:{
      'type': types.PAID
    }
  },
  {
    path: '/contas/previstas',
    name: 'financial-management-payables-expected',
    component: () => import('@/modules/financial_management/views/FinancialManagement.vue'),
    meta: {
      resource: FINANCIAL_MANAGMENT,
      action: READ_ACTION,
    },
    props:{
      'type': types.EXPECTED
    }
  },
  {
    path: '/contas/detalhes-faturas',
    name: 'financial-managment-invoices',
    component: () => import('@/modules/financial_management/views/InvoiceHistory.vue'),
    meta: {
      resource: FINANCIAL_MANAGMENT,
      action: READ_ACTION,
    }
  },
  {
    path: '/contas/:year?/:month?',
    name: 'financial-management-payables',
    component: () => import('@/modules/financial_management/views/FinancialManagement.vue'),
    meta: {
      resource: FINANCIAL_MANAGMENT,
      action: READ_ACTION,
    },
    props:{
      'type': types.PAYABLES
    }
  },
  {
    path: '/pagamento/importador',
    name: 'payment-importer',
    component: () => import('@/modules/financial_management/views/ImportPayments.vue'),
    meta: {
      resource: PAYMENT_IMPORT,
      action: READ_ACTION,
      pageTitle: 'Importação de Receitas'
    },
    props:{
      'type': types.PAYABLES
    }
  },
  {
    path: '/pagamentos/recebidos',
    name: 'contract-payments',
    component: () => import('@/modules/financial_management/views/FinancialContractPaymentsList.vue'),
    meta: {
      resource: FINANCIAL_MANAGMENT,
      action: READ_ACTION,
      pageTitle: 'Pagamentos recebidos'
    }
  },
  {
    path: '/pagamento/importador/detalhes/:year/:month',
    name: 'payment-import-details',
    component: () => import('@/modules/financial_management/views/ImportPaymentsDetails.vue'),
    meta: {
      resource: PAYMENT_IMPORT,
      action: READ_ACTION,
      pageTitle: 'Detalhes da importação'
    }
  },
]
  