import * as types from './types'

export default {
  [types.CONTRACTS_SEARCH_RESULT]: state => {
    return state.searchResult
  },
  [types.CONSULTANTS]: state => {
    return state.consultants
  },
  [types.FINANCIAL_SOLUTIONS]: state => {
    return state.financialSolutions
  },
  [types.FINANCIAL_SOLUTION_FIELDS]: state => {
    return state.financialSolutionFields
  },
  [types.FINANCIAL_SOLUTION_CALCULATION_RULES]: state => {
    return state.financialSolutionCalculationRules
  },
  [types.STATUS_LIST]: state => {
    return state.statusList
  },
  [types.EDIT_CONTRACT_SIDEBAR]: state => {
    return state.editContractSidebar
  },
  [types.ADD_RECEIPT_SIDEBAR]: state => {
    return state.addReceiptSidebar
  },
  [types.REQUEST_DELETION_SIDEBAR]: state => {
    return state.requestDeletionSidebar
  },
  [types.EDIT_SNAPSHOT_SIDEBAR]: state => {
    return state.editSnapshotSidebar
  },
  [types.DETAILS_GENERAL_INFORMATION]: state => {
    return state.details.generalInformation
  },
  [types.COMMENTS]: state => {
    return state.comments
  },
  [types.PAYMENTS]: state => {
    return state.payments
  },
  [types.COMMISSIONS]: state => {
    return state.commissions
  },
  [types.FILES]: state => {
    return state.files
  },
  [types.HISTORY]: state => {
    return state.history
  },
  [types.DELETION_REQUEST_SEARCH_RESULT]: state => {
    return state.deletionRequestSearchResult
  },
  [types.REQUEST_DELETION_APROVAL_SIDEBAR]: state => {
    return state.requestDeletionAprovalSidebar
  },
  [types.CUSTOMER_APPOINTMENTS]: state => {
    return state.customerAppointments
  },
  [types.EDIT_CONTRACT_OWNER_SIDEBAR]: state => {
    return state.editContractOwnerSidebar
  },
  [types.QUERY_ASAAS_ID_SIDEBAR]: state => {
    return state.queryAsaasIdSidebar
  },
}
