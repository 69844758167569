export const ADMIN_CREDENTIALS_STORAGE_KEY = 'token_admin'
export const CONSULTANT_LEVEL_PNH = 'PNH'
export const CONSULTANT_LEVEL_PNP = 'PNP'

export const APP_BACKOFFICE_ROLE_ID = '1'
export const APP_FINANCIAL_ROLE_ID = '2'
export const APP_ADMIN_ROLE_ID = '3'

export const APP_CUSTOMER_ROLE_ID = '5'

export const APP_BUSINESS_PARTNER_ROLE_PNH = '6'
export const APP_BUSINESS_PARTNER_ROLE_PNC = '24'
export const APP_BUSINESS_PARTNER_ROLE_PNP = '7'
export const APP_BUSINESS_PARTNER_ROLE_PNF = '8'
export const APP_BUSINESS_PARTNER_ROLE_PNM1 = '9'
export const APP_BUSINESS_PARTNER_ROLE_PNM2 = '10'
export const APP_BUSINESS_PARTNER_ROLE_PNM3 = '11'

export const APP_BUSINESS_PARTNER_ROLE_PNP1 = '25'
export const APP_BUSINESS_PARTNER_ROLE_PNP2 = '26'
export const APP_BUSINESS_PARTNER_ROLE_PNP3 = '27'

export const APP_BUSINESS_PARTNER_ROLE_PNS = '12'
export const APP_BUSINESS_PARTNER_ROLE_FF = '13'

export const LEADER_CONSULTANT_ROLES = [
    APP_BUSINESS_PARTNER_ROLE_PNF,
    APP_BUSINESS_PARTNER_ROLE_PNM1,
    APP_BUSINESS_PARTNER_ROLE_PNM2,
    APP_BUSINESS_PARTNER_ROLE_PNM3,
    APP_BUSINESS_PARTNER_ROLE_PNS,
    APP_BUSINESS_PARTNER_ROLE_FF
]

export function isLeaderConsultant(user_role){
    return user_role && LEADER_CONSULTANT_ROLES.includes(user_role.toString())
}