export default [
    {
      path: '/nao-encontrado',
      name: 'error-404',
      component: () => import('@/modules/miscellaneous/Error404.vue'),
      meta: {
        layout: 'full',
        public: true
      },
    },
    {
      path: '/em-breve',
      name: 'misc-coming-soon',
      component: () => import('@/modules/miscellaneous/ComingSoon.vue'),
      meta: {
        layout: 'full',
        public: true
      },
    },
    {
      path: '/nao-autorizado',
      name: 'misc-not-authorized',
      component: () => import('@/modules/miscellaneous/NotAuthorized.vue'),
      meta: {
        layout: 'full',
        public: true
      },
    },
    {
      path: '/em-manutencao',
      name: 'misc-under-maintenance',
      component: () => import('@/modules/miscellaneous/UnderMaintenance.vue'),
      meta: {
        layout: 'full',
        public: true
      },
    },
    {
      path: '/erro-inesperado',
      name: 'misc-error',
      component: () => import('@/modules/miscellaneous/Error.vue'),
      meta: {
        layout: 'full',
        public: true
      },
    },
    {
      path: '/misc/department-members',
      name: 'misc-department-members',
      component: () => import("@/modules/kpi/views/DepartmentMembers.vue"),
      meta: {
        public: true,
        pageTitle: "Responsáveis dos Departamentos"
      },
    },
    {
      path: '/faq',
      name: 'pages-faq',
      component: () => import('@/modules/miscellaneous/Faq.vue'),
      meta: {
        pageTitle: 'FAQ',
        breadcrumb: [
          {
            text: 'Pages',
          },
          {
            text: 'FAQ',
            active: true,
          },
        ],
        public: true
      },
    },
  ]
  